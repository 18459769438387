const protected_app = {
    name: 'Защищенное приложение',
    title: 'Создайте защищенное приложение: добавьте аутентификацию с простотой и эпической скоростью',
    description: 'Защищенное приложение безопасно сохраняет сеансы пользователей и проксирует запросы вашего приложения. Построенное на Cloudflare Workers, наслаждайтесь высочайшей производительностью и мгновенным запуском по всему миру. <a>Узнайте больше</a>',
    fast_create: 'Быстрое создание',
    modal_title: 'Создать защищенное приложение',
    modal_subtitle: 'Включите безопасную и быструю защиту всего в несколько кликов. Легко добавьте аутентификацию к вашему существующему веб-приложению.',
    form: {
        url_field_label: 'Ваш URL-адрес источника',
        url_field_placeholder: 'https://domain.com/',
        url_field_description: 'Укажите адрес вашего приложения, требующего защиты аутентификации.',
        url_field_modification_notice: 'Изменения в исходном URL могут потребовать до 1-2 минут для активации по всему мировому сетевому оборудованию.',
        url_field_tooltip: "Укажите адрес вашего приложения, за исключением любых '/pathname'. После создания вы сможете настроить правила аутентификации маршрутов.\n\nПримечание: сам по себе исходный URL не требует защиты аутентификации; защита применяется исключительно к доступам через указанный домен приложения.",
        domain_field_label: 'Домен приложения',
        domain_field_placeholder: 'your-domain',
        domain_field_description: 'Этот URL служит прокси для исходного URL с защитой аутентификации. Пользовательский домен может быть применен после создания.',
        domain_field_description_short: 'Этот URL служит прокси для исходного URL с защитой аутентификации.',
        domain_field_tooltip: "Приложения, защищенные через Logto, будут размещены по адресу 'your-domain.{{domain}}' по умолчанию. После создания может быть применен пользовательский домен.",
        create_application: 'Создать приложение',
        create_protected_app: 'Быстрое создание',
        errors: {
            domain_required: 'Требуется ваш домен.',
            domain_in_use: 'Это поддомен уже используется.',
            invalid_domain_format: "Недопустимый формат поддомена: используйте только строчные буквы, цифры и дефисы '-'.",
            url_required: 'Требуется исходный URL.',
            invalid_url: "Недопустимый формат исходного URL: используйте http:// или https://. Примечание: '/pathname' в настоящее время не поддерживается.",
            localhost: 'Пожалуйста, сначала дайте доступ к вашему локальному серверу из интернета. Узнайте больше о <a>локальной разработке</a>.',
        },
    },
    success_message: '🎉 Аутентификация приложения успешно включена! Исследуйте новый опыт вашего веб-сайта.',
};
export default Object.freeze(protected_app);
