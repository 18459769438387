const log_details = {
    page_title: 'Детали журнала аудита',
    back_to_logs: 'Назад к журналу аудита',
    back_to: 'Назад к {{name}}',
    success: 'Успешно',
    failed: 'Не удалось',
    event_key: 'Ключ события',
    application: 'Приложение',
    ip_address: 'IP адрес',
    user: 'Пользователь',
    log_id: 'ID журнала',
    time: 'Время',
    user_agent: 'User agent',
    tab_details: 'Детали',
    raw_data: 'Raw data',
};
export default Object.freeze(log_details);
