const menu = {
    profile: 'Профиль',
    language: 'Язык',
    appearance: {
        label: 'Внешний вид',
        light: 'Светлый режим',
        dark: 'Темный режим',
        system: 'Синхронизация с системой',
    },
    sign_out: 'Выйти',
};
export default Object.freeze(menu);
