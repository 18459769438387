const signing_keys = {
    title: 'Ключи подписи',
    description: 'Безопасное управление ключами подписи, используемыми вашими приложениями.',
    private_key: 'Приватные ключи OIDC',
    private_keys_description: 'Приватные ключи OIDC используются для подписи токенов JWT.',
    cookie_key: 'Ключи куки OIDC',
    cookie_keys_description: 'Ключи куки OIDC используются для подписи куки.',
    private_keys_in_use: 'Используемые закрытые ключи',
    cookie_keys_in_use: 'Используемые ключи cookie',
    rotate_private_keys: 'Повернуть закрытые ключи',
    rotate_cookie_keys: 'Повернуть ключи cookie',
    rotate_private_keys_description: 'Это действие создаст новый закрытый ключ подписи, повернет текущий ключ и удалит предыдущий. Ваши JWT-токены, подписанные текущим ключом, останутся действительными до удаления или следующего раунда поворота.',
    rotate_cookie_keys_description: 'Это действие создаст новый ключ cookie, повернет текущий ключ и удалит предыдущий. Ваши файлы cookie с текущим ключом останутся действительными до удаления или следующего раунда поворота.',
    select_private_key_algorithm: 'Выберите алгоритм подписи ключа для нового закрытого ключа',
    rotate_button: 'Повернуть',
    table_column: {
        id: 'ID',
        status: 'Статус',
        algorithm: 'Алгоритм подписи ключа',
    },
    status: {
        current: 'Текущий',
        previous: 'Предыдущий',
    },
    reminder: {
        rotate_private_key: 'Вы уверены, что хотите повернуть <strong>OIDC закрытые ключи</strong>? Новые выданные JWT-токены будут подписаны новым ключом. Существующие JWT-токены останутся действительными до следующего поворота.',
        rotate_cookie_key: 'Вы уверены, что хотите повернуть <strong>Ключи cookie OIDC</strong>? Новые файлы cookie, созданные в сеансах входа в систему, будут подписаны новым ключом cookie. Существующие файлы cookie останутся действительными до следующего поворота.',
        delete_private_key: 'Вы уверены, что хотите удалить <strong>OIDC закрытый ключ</strong>? Существующие JWT-токены, подписанные этим закрытым ключом, больше не будут действительными.',
        delete_cookie_key: 'Вы уверены, что хотите удалить <strong>Ключ cookie OIDC</strong>? Более старые сеансы входа в систему с файлами cookie, подписанными этим ключом cookie, больше не будут действительными. Для этих пользователей требуется повторная авторизация.',
    },
    messages: {
        rotate_key_success: 'Ключи подписи успешно повернуты.',
        delete_key_success: 'Ключ успешно удален.',
    },
};
export default Object.freeze(signing_keys);
