const contact = {
    title: 'Получить помощь',
    description: 'Для запросов на помощь и отзывов о продукте, свяжитесь с нами через следующие методы.',
    discord: {
        title: 'Присоединиться к сообществу Discord',
        description: 'Свяжитесь с другими разработчиками, чтобы найти решения',
        button: 'Присоединиться',
    },
    github: {
        title: 'Общение на GitHub',
        description: 'Создайте проблему и отправьте на GitHub',
        button: 'Открыть',
    },
    email: {
        title: 'Связаться с поддержкой по электронной почте',
        description: 'Отправьте нам электронное письмо для получения дополнительной информации и помощи',
        button: 'Отправить',
    },
    reserve: {
        title: 'Запишитесь на встречу с командой Logto',
        description: 'Быстро запишитесь на сессию для онлайн-чата',
        button: 'Записаться',
    },
};
export default Object.freeze(contact);
