const dashboard = {
    page_title: 'Dashboard',
    title: 'Dashboard',
    description: 'Получите обзор о производительности вашего приложения',
    total_users: 'Всего пользователей',
    total_users_tip: 'Всего пользователей',
    new_users_today: 'Новые пользователи сегодня',
    new_users_today_tip: 'Количество новых пользователей, зарегистрировавшихся сегодня в вашем приложении',
    new_users_7_days: 'Новые пользователи за последние 7 дней',
    new_users_7_days_tip: 'Количество новых пользователей, зарегистрировавшихся за последние 7 дней в вашем приложении',
    daily_active_users: 'Ежедневные активные пользователи',
    daily_active_users_tip: 'Количество уникальных пользователей, обменивающих токены в вашем приложении сегодня',
    weekly_active_users: 'Еженедельные активные пользователи',
    weekly_active_users_tip: 'Количество уникальных пользователей, обменивающих токены в вашем приложении за последние 7 дней',
    monthly_active_users: 'Ежемесячные активные пользователи',
    monthly_active_users_tip: 'Количество уникальных пользователей, обменивающих токены в вашем приложении за последние 30 дней',
};
export default Object.freeze(dashboard);
