const invitation = {
    find_your_tenants: 'Найти своих арендаторов',
    find_tenants_description: 'Ваш адрес электронной почты может уже быть зарегистрирован у нескольких арендаторов. Вы можете выбрать присоединиться к уже существующим или продолжить создание нового.',
    create_new_tenant: 'Создать нового арендатора',
    email_not_match_title: 'Вы вошли в систему как\n{{email}}',
    email_not_match_description: 'Пожалуйста, войдите под правильной учетной записью, чтобы принять приглашение и стать участником организации.',
    switch_account: 'Войти под другой учетной записью',
    invalid_invitation_status: 'Недействительное приглашение. Пожалуйста, свяжитесь с администратором и повторите попытку.',
    invitation_not_found: 'Приглашение не найдено. Пожалуйста, свяжитесь с администратором.',
};
export default Object.freeze(invitation);
