const logs = {
    page_title: 'Записи аудита',
    title: 'Записи аудита',
    subtitle: 'Просмотр данных журнала событий аутентификации, сделанных вашими пользователями',
    event: 'Событие',
    user: 'Пользователь',
    application: 'Приложение',
    time: 'Время',
    filter_by: 'Фильтр по',
};
export default Object.freeze(logs);
