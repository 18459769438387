const verification_code = {
    phone_email_empty: 'Оба поля "телефон" и "эл. почта" пустые.',
    not_found: 'Код верификации не найден. Пожалуйста, сначала отправьте код верификации.',
    phone_mismatch: 'Телефон не соответствует. Пожалуйста, запросите новый код верификации.',
    email_mismatch: 'Эл. почта не соответствует. Пожалуйста, запросите новый код верификации.',
    code_mismatch: 'Недействительный код верификации.',
    expired: 'Срок действия кода верификации истек. Пожалуйста, запросите новый код верификации.',
    exceed_max_try: 'Превышено ограничение на количество попыток ввода кода верификации. Пожалуйста, запросите новый код верификации.',
};
export default Object.freeze(verification_code);
