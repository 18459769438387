const organization_details = {
    page_title: 'Детали организации',
    delete_confirmation: 'После удаления все участники потеряют свою членство и роли в данной организации. Это действие нельзя отменить.',
    organization_id: 'Идентификатор организации',
    settings_description: 'Организации представляют команды, бизнес-клиентов и партнерские компании, которые могут получить доступ к вашим приложениям.',
    name_placeholder: 'Название организации, не обязательно должно быть уникальным.',
    description_placeholder: 'Описание организации.',
    member: 'Участник',
    member_other: 'Участники',
    add_members_to_organization: 'Добавить участников в организацию {{name}}',
    add_members_to_organization_description: 'Найдите подходящих пользователей, выполнив поиск по имени, электронной почте, телефону или идентификатору пользователя. Существующие участники не отображаются в результатах поиска.',
    add_with_organization_role: 'Добавить с ролями организации',
    user: 'Пользователь',
    application: 'Приложение',
    application_other: 'Приложения',
    add_applications_to_organization: 'Добавить приложения в организацию {{name}}',
    add_applications_to_organization_description: 'Найдите подходящие приложения, выполнив поиск по ID приложения, названию или описанию. Существующие приложения не отображаются в результатах поиска.',
    at_least_one_application: 'Необходимо хотя бы одно приложение.',
    remove_application_from_organization: 'Удалить приложение из организации',
    remove_application_from_organization_description: 'После удаления приложение потеряет свою ассоциацию и роли в данной организации. Это действие нельзя отменить.',
    search_application_placeholder: 'Поиск по ID приложения, названию или описанию',
    roles: 'Роли организации',
    authorize_to_roles: 'Разрешить {{name}} доступ к следующим ролям:',
    edit_organization_roles: 'Редактирование ролей организации',
    edit_organization_roles_title: 'Редактирование ролей организации {{name}}',
    remove_user_from_organization: 'Удалить пользователя из организации',
    remove_user_from_organization_description: 'После удаления пользователь потеряет свое членство и роли в этой организации. Это действие нельзя отменить.',
    search_user_placeholder: 'Поиск по имени, электронной почте, телефону или идентификатору пользователя',
    at_least_one_user: 'Необходимо указать хотя бы одного пользователя.',
    organization_roles_tooltip: 'Назначенные роли {{type}} в данной организации.',
    custom_data: 'Кастомные данные',
    custom_data_tip: 'Кастомные данные представляют собой JSON-объект, который может использоваться для хранения дополнительных данных, связанных с организацией.',
    invalid_json_object: 'Некорректный JSON-объект.',
    branding: {
        logo: 'Логотипы организации',
        logo_tooltip: 'Вы можете использовать идентификатор организации для отображения этого логотипа при входе в систему; потребуется темная версия логотипа, если в настройках omni sign-in включен темный режим. <a>Подробнее</a>',
    },
    jit: {
        title: 'Создание по требованию',
        description: 'Пользователи могут автоматически присоединиться к организации и получить роли при первом входе через некоторые методы аутентификации. Вы можете установить требования для создания по требованию.',
        email_domain: 'Почтовый домен для создания по требованию',
        email_domain_description: 'Новые пользователи, регистрирующиеся с проверенными адресами электронной почты или через социальный вход с проверенными адресами электронной почты, автоматически присоединятся к организации. <a>Подробнее</a>',
        email_domain_placeholder: 'Введите почтовые домены для создания по требованию',
        invalid_domain: 'Некорректный домен',
        domain_already_added: 'Домен уже добавлен',
        sso_enabled_domain_warning: 'Вы ввели один или несколько почтовых доменов, связанных с корпоративным SSO. Пользователи с этими почтовыми адресами будут следовать стандартному SSO процессу и не будут добавлены в эту организацию, если не настроено корпоративное SSO.',
        enterprise_sso: 'Корпоративное SSO',
        no_enterprise_connector_set: 'Вы еще не настроили ни одного корпоративного SSO-коннектора. Сначала добавьте коннекторы, чтобы включить корпоративное SSO. <a>Настроить</a>',
        add_enterprise_connector: 'Добавить корпоративный коннектор',
        enterprise_sso_description: 'Новые пользователи или существующие пользователи, впервые входящие с помощью корпоративного SSO, автоматически присоединятся к организации. <a>Подробнее</a>',
        organization_roles: 'Роли организации по умолчанию',
        organization_roles_description: 'Назначайте роли пользователям при присоединении к организации через создание по требованию.',
    },
    mfa: {
        title: 'Многофакторная аутентификация (MFA)',
        tip: 'Когда требуется MFA, пользователи без настроенной MFA будут отвергнуты при попытке обмена токена организации. Эта настройка не влияет на аутентификацию пользователей.',
        description: 'Требовать от пользователей настройки многофакторной аутентификации для доступа к этой организации.',
        no_mfa_warning: 'В вашем тенанте не включено ни одного метода многофакторной аутентификации. Пользователи не смогут получить доступ к этой организации, пока не будет включен хотя бы один <a>метод многофакторной аутентификации</a>.',
    },
};
export default Object.freeze(organization_details);
